import component from './AsssistantSubmit.vue';
import {
  FormCheckboxValueType,
  FormMultipleChoiceValueType,
  FormTextFieldValueType,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import {
  buildFormMultipleChoiceField,
  buildFormSelect,
  buildFormTextField,
} from '@/lib/forms/builder';
import { ValidationType } from '@/consts/validation-types';
import { sendProductRequest } from '@/lib/submit/send-product-request';
import { uploadFiles } from '@/lib/submit/upload-files';
import { getFilesFromContext } from '@/lib/xstate/machine';
import {
  getMailTemplate,
  getProductId,
  getProductRequest,
} from '~/helper/request-product';
import { getRequestCommentFromContext } from '~/helper/request-comment';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import i18n from '@/plugins/i18n';
import { FormFieldGroupId } from '~/consts/assistant-form-field-groups';
import {
  getMailToAddress,
  getContentString,
  sendMail,
} from '@/lib/submit/send-mail';
import { sendEvent } from '@/lib/analytics/dataLayer';

export interface AssistantSubmitStepContext extends AssistantStepContext {
  companyName: FormTextFieldValueType;
  firstname: FormTextFieldValueType;
  lastname: FormTextFieldValueType;
  street: FormTextFieldValueType;
  streetNumber: FormTextFieldValueType;
  zipcode: FormTextFieldValueType;
  city: FormTextFieldValueType;
  phone: FormTextFieldValueType;
  email: FormTextFieldValueType;
  privacyConfirmation: FormCheckboxValueType;
  buildingType?: FormMultipleChoiceValueType;
  solarRoofShape?: FormMultipleChoiceValueType;
}

const config = {
  component,
  hideSkipMessage: () => true,
  id: StepId.ASSISTANT_SUBMIT,
  categoryId: StepCategoryId.SUBMIT,
  fields: [
    buildFormMultipleChoiceField('salutation', {
      component: {
        options: [
          {
            value: 'm',
          },
          {
            value: 'w',
          },
        ],
        columns: 2,
        singleAnswer: true,
      },
      required: true,
      groupId: FormFieldGroupId.CONTACT,
    }),
    buildFormTextField('firstname', {
      required: true,
      groupId: FormFieldGroupId.CONTACT,
    }),
    buildFormTextField('lastname', {
      required: true,
      groupId: FormFieldGroupId.CONTACT,
    }),
    buildFormTextField('street', {
      required: true,
      groupId: FormFieldGroupId.CONTACT,
    }),
    buildFormTextField('streetNumber', {
      required: true,
      groupId: FormFieldGroupId.CONTACT,
    }),
    buildFormTextField('zipcode', {
      required: true,
      validation: [ValidationType.postalCodeDE],
      groupId: FormFieldGroupId.CONTACT,
    }),
    buildFormTextField('city', {
      required: true,
      groupId: FormFieldGroupId.CONTACT,
    }),
    buildFormTextField('phone', {
      required: true,
      validation: [ValidationType.phoneNumber],
      groupId: FormFieldGroupId.CONTACT,
    }),
    buildFormTextField('email', {
      component: {
        type: 'email',
      },
      required: true,
      validation: [ValidationType.email],
      groupId: FormFieldGroupId.CONTACT,
    }),
    buildFormTextField('note', {
      required: false,
      outputFormatter: (data) => {
        if (data.formattedValue === '0' || data.formattedValue === '–') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        }
        return {
          formattedLabel: data?.formattedLabel,
          formattedValue: data?.formattedValue,
        };
      },
      groupId: FormFieldGroupId.CONTACT,
    }),
    buildFormSelect('referral', {
      component: {
        items: [
          'personal-visit',
          'promotion-internet',
          'promotion-facebook',
          'advertisement',
          'recommendation',
          'e-store',
          'fair',
          'canvasser',
          'invoice-inclosure',
        ],
      },
      outputFormatter: (data) => {
        if (data.formattedValue === '' || data.formattedValue === '–') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        }
        return {
          formattedLabel: i18n.t(
            'assistantSubmit.formFields.referral.formattedLabel',
          ) as string,
          formattedValue: data?.formattedValue,
        };
      },
      groupId: FormFieldGroupId.OTHER,
    }),
    buildFormMultipleChoiceField('solarRoofShape', {
      component: {
        options: [
          {
            value: 'storage',
          },
          {
            value: 'wallbox',
          },
        ],
        columns: 1,
        singleAnswer: false,
      },
      groupId: FormFieldGroupId.OTHER,
      required: false,
      outputFormatter: (data) => {
        if (data.formattedValue === '0' || data.formattedValue === '–') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        }
        return {
          formattedLabel: i18n.t(
            'assistantSubmit.formFields.solarRoofShape.formattedLabel',
          ) as string,
          formattedValue: data?.formattedValue,
        };
      },
    }),
    buildFormMultipleChoiceField('buildingType', {
      component: {
        options: [
          {
            value: 'single',
          },
          {
            value: 'semi-detached',
          },
          {
            value: 'townhouse',
          },
          {
            value: 'multi',
          },
        ],
        columns: 1,
        singleAnswer: true,
      },
      groupId: FormFieldGroupId.OBJECT,
      required: false,
      outputFormatter: (data) => {
        if (data.formattedValue === '0' || data.formattedValue === '–') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        }
        return {
          formattedLabel: i18n.t(
            'assistantSubmit.formFields.buildingType.formattedLabel',
          ) as string,
          formattedValue: data?.formattedValue,
        };
      },
    }),
  ],
  submit: async ({ i18n, steps, assistantContext }): Promise<void> => {
    try {
      const contextFiles = getFilesFromContext(assistantContext);
      const s3UploadedFilePaths = await uploadFiles(contextFiles);

      const assistant = document.querySelector(
        '.sidebar header ul > li.active',
      ) as HTMLLIElement;

      const categoryId = assistant.getAttribute(
        'data-category',
      ) as StepCategoryId;

      /*
      ###############################################################################
      ########################### send product request ##############################
      ###############################################################################
      */

      const requestComment = getRequestCommentFromContext(
        steps,
        assistantContext,
        [categoryId, StepCategoryId.SUBMIT],
      );

      // console.log(requestComment);

      const contactData = assistantContext.assistantSubmit;
      const salutation = i18n.t(
        `assistantSubmit.formFields.salutation.options.${contactData.salutation[0]}.label`,
      );

      const mailSubject = i18n.t('mail.subject');
      const mailTemlate = await getMailTemplate();

      const contentString = getContentString(steps, assistantContext, [
        categoryId,
        StepCategoryId.SUBMIT,
      ]);

      const htmlString = mailTemlate
        .replace('{{subject}}', mailSubject)
        .replace('{{content}}', contentString);

      const mailData = {
        mailSubject: mailSubject,
        mailBody: htmlString,
        mailFromAddress: 'no-reply@stawag.de',
        mailToAddress: getMailToAddress(categoryId),
      };

      const productId = getProductId(categoryId);
      const productRequest = getProductRequest(
        contactData,
        requestComment,
        productId,
        salutation,
      );

      // console.log(mailData);
      // console.log(productRequest);

      await sendProductRequest({
        productRequest,
        shopHost: process.env.VUE_APP_STAWAG_HOST,
        shopApiKey: process.env.VUE_APP_STAWAG_API_KEY,
        shopUseBasicAuth: process.env.VUE_APP_STAWAG_USE_BASIC_AUTH === 'true',
        shopBasicAuth:
          process.env.VUE_APP_STAWAG_USE_BASIC_AUTH === 'true'
            ? process.env.VUE_APP_STAWAG_BASIC_AUTH
            : undefined,
        disableCustomerMail:
          process.env.VUE_APP_SHOP_DISABLE_CUSTOMER_MAIL === 'true',
        s3BucketName: process.env.VUE_APP_ASSISTANT_S3_BUCKET,
        s3FileAttachmentPaths: s3UploadedFilePaths,
      });
      sendMail(mailData);

      /**
       *  Google Tag Manager push dataLayer
       */
      let gaEvent = '';
      switch (categoryId) {
        case StepCategoryId.HEATING:
          gaEvent = 'shop_assistant_lead_waerme';
          break;
        case StepCategoryId.EMOBILITY:
          gaEvent = 'shop_assistant_lead_installationscheck';
          break;
        case StepCategoryId.SOLAR:
          gaEvent = 'shop_assistant_lead_solar';
          break;
      }
      sendEvent({ eventName: gaEvent });

      return;
    } catch (error) {
      let errorMessage = i18n.t('message.error').toString();
      if (error instanceof Error) {
        errorMessage = error.message;
      }

      // eslint-disable-next-line no-console
      console.error(errorMessage);

      throw new Error(errorMessage);
    }
  },
};

export default config;
