import component from './SolarOffer.vue';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import { StepDefinition } from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';

export interface SolarOfferStepContext extends AssistantStepContext {}

const config: StepDefinition = {
  component,
  hideSkipMessage: () => true,
  fields: [],
  id: StepId.SOLAR_OFFER,
  categoryId: StepCategoryId.SOLAR,
};

export default config;
