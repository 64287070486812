var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gc-multiple-choice gc-mb-4"},[_c('gc-row',_vm._l((_vm.filteredOptions),function(option){return _c('gc-col',{key:option.value,staticClass:"gc-py-0 gc-flex",attrs:{"cols":_vm.isMd ? 12 / _vm.columns : 12}},[_c('div',{staticClass:"gc-multiple-choice-option gc-w-full gc-p-3 gc-mb-2 gc-flex gc-items-center gc-rounded gc-border-2 gc-transition-all gc-duration-150 gc-ease-in-out gc-cursor-pointer",class:{
          'gc-single-answer': _vm.singleAnswer,
          'gc-bg-white gc-border-primary': _vm.value.includes(option.value),
          'gc-border-gray-100 hover:gc-border-gray-300': !_vm.value.includes(
            option.value
          ),
          'gc-justify-center': _vm.verticalOptionLayout,
        },attrs:{"data-test-id":"option"},on:{"click":function($event){return _vm.selectOption(option.value)}}},[_c('div',{staticClass:"gc-flex gc-justify-start gc-items-center",class:{ 'gc-flex-col-reverse': _vm.verticalOptionLayout }},[(!_vm.disableUnselect && !_vm.hideCheckbox)?_c('multiple-choice-checkbox',{class:{
              'gc-mt-2': _vm.verticalOptionLayout,
              'gc-mr-3': !_vm.verticalOptionLayout,
            },attrs:{"selected":_vm.value.includes(option.value),"single-answer":_vm.singleAnswer}}):_vm._e(),_c('div',[_vm._t("customOption",function(){return [_c('div',{staticClass:"gc-flex gc-justify-start gc-items-center",class:{ 'gc-flex-col': _vm.verticalOptionLayout }},[(option.icon)?_c('div',{class:{
                    'gc-mb-2': _vm.verticalOptionLayout,
                    'gc-mr-2': !_vm.verticalOptionLayout,
                  }},[_c('font-awesome-icon',{staticClass:"gc-text-primary",attrs:{"icon":option.icon,"size":"2x"}})],1):_vm._e(),(option.image)?_c('div',{class:{
                    'gc-mb-2': _vm.verticalOptionLayout,
                    'gc-mr-2': !_vm.verticalOptionLayout,
                  }},[_c('gc-image',{attrs:{"src":(_vm.baseUrl + "/" + (option.image.file)),"height":option.image.options.height,"width":option.image.options.width}})],1):_vm._e(),_c('div',{staticClass:"gc-multiple-choice-option__content",class:{ 'gc-text-center': _vm.verticalOptionLayout }},[_c('span',{staticClass:"text-subtitle-1 gc-font-bold"},[_vm._v(" "+_vm._s(_vm.$t( _vm.getOptionLabelString( _vm.assistantStep.id, _vm.fieldName, option.value ) ))+" ")]),(
                      _vm.$te(
                        _vm.getOptionDescriptionString(
                          _vm.assistantStep.id,
                          _vm.fieldName,
                          option.value
                        )
                      )
                    )?_c('small',{staticClass:"gc-block gc-text-opacity-75"},[_vm._v(" "+_vm._s(_vm.$t( _vm.getOptionDescriptionString( _vm.assistantStep.id, _vm.fieldName, option.value ) ))+" ")]):_vm._e()])])]},{"option":option})],2)],1)])])}),1),(_vm.enableCustomOption)?_c('div',{staticClass:"gc-flex gc-mutliple-choice-option gc-px-0 gc-pt-2"},[_c('gc-text-field',{staticClass:"gc-mb-0",attrs:{"label":_vm.$t("customOption"),"dense":"","outlined":""},on:{"focus":function($event){return _vm.handleInputFocus()}},model:{value:(_vm.customOption),callback:function ($$v) {_vm.customOption=$$v},expression:"customOption"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }