


































































































































import { VInput } from 'vuetify/lib';
import { PropType, computed, ref, watch } from '@vue/composition-api';
import GcCard from '@/components/primitives/GcCard.vue';
import GcImage from '@/components/primitives/GcImage.vue';
import MultipleChoiceCheckbox from '@/components/MultipleChoiceCheckbox.vue';
import GcTextField from '@/components/primitives/GcTextField.vue';
import {
  MultipleChoiceOption,
  StepDefinition,
} from '@/interfaces/step-definition';
import { useResponsiveBreakpoints } from '@/lib/ui-helper/responsive';
import GcCol from '@/components/primitives/GcCol.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import {
  getOptionDescriptionString,
  getOptionLabelString,
} from '@/lib/forms/helper';
import { AssistantContext } from '~/steps/steps';
import { FormMultipleChoiceComponentConfig } from '@/interfaces/step-definition';

const checkPropCompatibility = (props: FormMultipleChoiceComponentConfig) => {
  if (props.nextStep && !props.singleAnswer) {
    throw Error(
      'properties nextStep=true and singleAnswer=false are incompatible',
    );
  }
};

export default VInput.extend({
  components: {
    GcCard,
    GcCol,
    GcImage,
    GcRow,
    GcTextField,
    MultipleChoiceCheckbox,
  },
  props: {
    assistantContext: {
      default: () => ({}),
      type: Object as PropType<AssistantContext>,
    },
    assistantStep: {
      required: true,
      type: Object as PropType<StepDefinition>,
    },
    columns: {
      default: 1,
      type: Number,
    },
    disableUnselect: {
      default: false,
      type: Boolean,
    },
    hideCheckbox: {
      default: false,
      type: Boolean,
    },
    enableCustomOption: {
      default: false,
      type: Boolean,
    },
    fieldName: {
      required: true,
      type: String,
    },
    label: {
      default: '',
      type: String,
    },
    nextStep: {
      default: false,
      type: Boolean,
    },
    options: {
      type: Array as PropType<Array<MultipleChoiceOption>>,
    },
    singleAnswer: {
      default: false,
      type: Boolean,
    },
    verticalOptionLayout: {
      default: false,
      type: Boolean,
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setup: (props: any, { emit }) => {
    checkPropCompatibility(props);

    const baseUrl = process.env.VUE_APP_BASE_URL;

    const { isMd } = useResponsiveBreakpoints();

    const filteredOptions = computed(() =>
      (props.options as Array<MultipleChoiceOption>).filter((option) =>
        option.isVisible !== undefined && typeof option.isVisible === 'function'
          ? option.isVisible(props.assistantContext)
          : true,
      ),
    );

    const getCustomValue = () => {
      if (!props.value || props.value.length === 0) {
        return '';
      }
      const customValues = props.value.filter(
        (v: string) =>
          !props.options?.map((o: MultipleChoiceOption) => o.value).includes(v),
      );
      return customValues.length > 0 ? customValues[0] : '';
    };

    const customOption = ref<string>(getCustomValue());
    const customOptionSelected = computed(
      () =>
        props.enableCustomOption &&
        customOption.value.length > 0 &&
        props.value.includes(customOption.value),
    );

    watch(props, () => {
      if (
        props.singleAnswer &&
        customOption.value.length > 0 &&
        props.value[0] !== customOption.value
      ) {
        customOption.value = '';
      }
    });

    watch(customOption, (val, oldVal) => {
      if (
        val.length === 0 &&
        props.value.length > 0 &&
        props.options
          .map((o: MultipleChoiceOption) => o.value)
          .includes(props.value[0])
      ) {
        return;
      }
      if (props.singleAnswer) {
        emit('input', val.trim().length > 0 ? [val] : []);
      } else {
        const oldValueIndex = props.value.findIndex(
          (v: string) => v === oldVal.trim(),
        );
        let updatedArray = [...props.value];
        if (oldValueIndex > -1) {
          updatedArray[oldValueIndex] = val.trim();
        } else {
          updatedArray.push(val.trim());
        }
        updatedArray = updatedArray.filter((v) => v.trim().length > 0);
        emit('input', updatedArray);
      }
    });

    const selectOption = (id: string) => {
      if (id.length === 0) {
        emit('input', []);
        return;
      }

      if (props.singleAnswer && props.disableUnselect) {
        emit('input', [id]);
        return;
      }

      const oldValueIndex = props.value.findIndex((v: string) => v === id);
      const updatedArray = [...props.value];
      if (props.singleAnswer) {
        updatedArray.splice(0);
        if (oldValueIndex === -1) {
          updatedArray.push(id);
        }
      } else if (oldValueIndex > -1) {
        updatedArray.splice(oldValueIndex, 1);
      } else {
        updatedArray.push(id);
      }
      emit('input', updatedArray);

      if (props.nextStep) {
        emit('next-step');
      }
    };

    const handleInputFocus = () => {
      if (
        props.singleAnswer &&
        props.value.length > 0 &&
        customOption.value.length === 0
      ) {
        emit('input', []);
      }
    };

    return {
      baseUrl,
      customOption,
      customOptionSelected,
      filteredOptions,
      getOptionDescriptionString,
      getOptionLabelString,
      handleInputFocus,
      isMd,
      selectOption,
    };
  },
});
