

















































































































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import AssistantStep from '@/components/AssistantStep.vue';
import GcForm from '@/components/primitives/GcForm.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import { getStepContext } from '@/lib/context';
import { stepComponentProps } from '@/lib/steps/helper';
import FormField from '@/components/FormField.vue';
import { useBaseTransitions } from '@/lib/xstate/transitions';
import GcCol from '@/components/primitives/GcCol.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import AssistantHeader from '~/components/AssistantHeader.vue';
import { AssistantContext } from '~/steps/steps';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import { EventBus } from '@/main';
import { saveStateToLocalStorage } from '@/lib/storage/local-storage';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcCol,
    GcForm,
    GcRow,
    AssistantHeader,
  },
  emits: ['transition', 'reset-data'],
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const { onNext } = useBaseTransitions(
      emit,
      props.assistantContext,
      props.step,
    );

    const useLocalStorage = process.env.VUE_APP_USE_LOCALSTORAGE === 'true';
    const formData = getStepContext(props.assistantContext, props.step);
    const formValid = ref(false);
    const sentSuccessfully = ref(false);
    const interestValue = props.assistantContext.emobilityInterest.interest;
    const showRequiredHint = computed<boolean>(
      (): boolean =>
        process.env.VUE_APP_SHOW_REQUIRED_HINT === 'true' &&
        Boolean(
          props.step?.fields &&
            props.step?.fields.some((field) => field.required === true),
        ),
    );

    const assistant = document.querySelector(
      '.sidebar header ul > li.active',
    ) as HTMLLIElement;

    const categoryId = computed(
      () => assistant.getAttribute('data-category') as StepCategoryId,
    );

    const prevPage = computed(() => {
      switch (categoryId.value) {
        case StepCategoryId.EMOBILITY:
          return interestValue.includes('buy')
            ? StepId.EMOBILITY_BUY
            : StepId.EMOBILITY_INTEREST;

        case StepCategoryId.HEATING:
          return StepId.HEATING_BUILDING_TYPE;
        case StepCategoryId.SOLAR:
          return StepId.SOLAR_POWER_USAGE;
      }
      return null;
    });

    if (useLocalStorage) {
      saveStateToLocalStorage(
        props.assistantContext,
        props.step ? props.step.id : '',
        categoryId.value,
      );

      watch(props.assistantContext, (value: AssistantContext) => {
        saveStateToLocalStorage(
          value,
          props.step ? props.step.id : '',
          categoryId.value,
        );
      });
    }

    const onPrev = () => {
      emit('transition', {
        event: 'BACK_TO_' + prevPage.value?.toUpperCase(),
      });
    };

    const onSend = () => {
      sentSuccessfully.value = true;
      EventBus.$emit('reset-data');
    };

    let headerTitle = '';

    if (categoryId.value === StepCategoryId.EMOBILITY) {
      headerTitle =
        prevPage.value === StepId.EMOBILITY_BUY
          ? 'emobilityBuy.title'
          : 'emobilityCheck.title';
    }

    return {
      formData,
      formValid,
      StepId,
      prevPage,
      onPrev,
      onNext,
      onSend,
      showRequiredHint,
      sentSuccessfully,
      headerTitle,
      categoryId,
      StepCategoryId,
    };
  },
});
