// Each step has its own identifier
export enum StepId {
  EMOBILITY_INTEREST = 'emobilityInterest',
  EMOBILITY_BUY = 'emobilityBuy',
  SOLAR_ROOF_SHAPE = 'solarRoofShape',
  SOLAR_ROOF_DIRECTION = 'solarRoofDirection',
  SOLAR_POWER_USAGE = 'solarPowerUsage',
  SOLAR_OFFER = 'solarOffer',
  HEATING_TYPE = 'heatingType',
  HEATING_USAGE = 'heatingUsage',
  HEATING_DISTRIBUTION = 'heatingDistribution',
  HEATING_CONSTRUCTION_YEAR = 'heatingConstructionYear',
  HEATING_BUILDING_TYPE = 'heatingBuildingType',
  ASSISTANT_SUBMIT = 'assistantSubmit',
}

// You can optionally divide steps into groups,
// each one having its own identifier
export enum StepCategoryId {
  ROOT = 'root',
  SUBMIT = 'submit',
  EMOBILITY = 'emobility',
  SOLAR = 'solar',
  HEATING = 'heating',
}

export enum StepGroupId {}
