declare const dataLayer: Array<Record<string, unknown>>;

interface EventData {
  eventName: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  timestamp?: string;
}

/* eslint-disable no-console */
export const sendEvent = (data: EventData) => {
  console.log(`pushed event: ${data.eventName}`);
  if (typeof window === 'undefined') {
    console.warn(
      'running a non-browser environment (SSR?) - no dataLayer available',
    );
    return;
  }

  if (typeof dataLayer === 'undefined') {
    console.warn('DataLayer is not defined');
    return;
  }

  const { eventName, ...rest } = data;

  dataLayer.push({
    event: eventName,
    timestamp: new Date().toISOString(),
    ...rest,
  });
};
/* eslint-enable no-console */
