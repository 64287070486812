




























































import { defineComponent, ref } from '@vue/composition-api';
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
} from '@fortawesome/pro-regular-svg-icons';
import SkipMessage from '@/components/SkipMessage.vue';
import { JUMP_TO_CONTACTFORM } from '~/consts/assistant-state-events';
import AssistantStep from '@/components/AssistantStep.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import AssistantStepsOverview from '@/components/AssistantStepsOverview.vue';
import { useBaseTransitions } from '@/lib/xstate/transitions';
import { getStepContext } from '@/lib/context';
import { stepComponentProps } from '@/lib/steps/helper';
import GcForm from '@/components/primitives/GcForm.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcButton from '@/components/primitives/GcButton.vue';
import FormField from '@/components/FormField.vue';
import { StepId } from '~/consts/assistant-steps';
import solarSteps from '~/steps/solar/steps';

export default defineComponent({
  components: {
    SkipMessage,
    GcForm,
    GcRow,
    GcCol,
    GcButton,
    FormField,
    AssistantStep,
    AssistantStepNavigation,
    AssistantStepsOverview,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const formData = getStepContext(props.assistantContext, props.step);
    const formValid = ref(false);
    const { onPrev, onNext } = useBaseTransitions(
      emit,
      props.assistantContext,
      props.step,
    );
    const solarStep = props.steps?.filter(
      (step) => step.id === StepId.SOLAR_INTEREST,
    )[0];
    const currentStepIndex = 1;

    const jumpToContactForm = (): void => {
      emit('transition', {
        event: JUMP_TO_CONTACTFORM,
      });
    };

    return {
      formData,
      formValid,
      StepId,
      onPrev,
      onNext,
      jumpToContactForm,
      faCheck,
      faArrowLeft,
      faArrowRight,
      solarSteps,
      solarStep,
      context: props.assistantContext,
      currentStepIndex,
    };
  },
});
