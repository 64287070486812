






















import { defineComponent } from '@vue/composition-api';
import SkipMessage from '@/components/SkipMessage.vue';
import { JUMP_TO_CONTACTFORM } from '~/consts/assistant-state-events';
import AssistantStep from '@/components/AssistantStep.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import AssistantStepsOverview from '@/components/AssistantStepsOverview.vue';
import { useBaseTransitions } from '@/lib/xstate/transitions';
import { stepComponentProps } from '@/lib/steps/helper';
import GcForm from '@/components/primitives/GcForm.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcButton from '@/components/primitives/GcButton.vue';
import FormField from '@/components/FormField.vue';
import { StepId } from '~/consts/assistant-steps';
import solarSteps from '~/steps/solar/steps';

export default defineComponent({
  components: {
    SkipMessage,
    GcForm,
    GcRow,
    GcCol,
    GcButton,
    FormField,
    AssistantStep,
    AssistantStepNavigation,
    AssistantStepsOverview,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const { onPrev } = useBaseTransitions(
      emit,
      props.assistantContext,
      props.step,
    );
    const solarStep = props.steps?.filter(
      (step) => step.id === StepId.SOLAR_OFFER,
    )[0];

    const jumpToContactForm = (): void => {
      emit('transition', {
        event: JUMP_TO_CONTACTFORM,
      });
    };

    const onNext = () => {
      emit('transition', {
        event: 'JUMP_TO_' + StepId.ASSISTANT_SUBMIT.toUpperCase(),
      });
    };

    const powerUsage = Number(
      props.assistantContext[StepId.SOLAR_POWER_USAGE].powerUsage,
    );
    const baseUrl = process.env.VUE_APP_BASE_URL;

    let image = 'Solar_2-Pers.png';

    if (powerUsage > 2400 && powerUsage <= 3000) {
      image = 'Solar_2-Pers.png';
    }
    if (powerUsage > 3000 && powerUsage <= 3600) {
      image = 'Solar_3-Pers.png';
    }
    if (powerUsage > 3600 && powerUsage <= 4000) {
      image = 'Solar_4-Pers.png';
    }
    if (powerUsage > 4000) {
      image = 'Solar_4-Pers_emob.png';
    }

    return {
      onPrev,
      onNext,
      jumpToContactForm,
      solarSteps,
      solarStep,
      baseUrl,
      image,
    };
  },
});
