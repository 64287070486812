import component from './HeatingConstructionYear.vue';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import {
  FormSliderComponentConfig,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { buildFormSlider } from '@/lib/forms/builder';
import i18n from '@/plugins/i18n';
import { FormFieldGroupId } from '~/consts/assistant-form-field-groups';

export interface HeatingConstructionYearStepContext
  extends AssistantStepContext {
  constructionYear: FormSliderComponentConfig;
}
const min = 1980;
const max = new Date().getFullYear();
const count = max - min;
const tickLabels: Array<string> = [];

tickLabels[0] = i18n
  .t('heatingConstructionYear.formFields.constructionYear.options.1980.label')
  .toString();
tickLabels[count / 2] = i18n
  .t('heatingConstructionYear.formFields.constructionYear.options.2000.label')
  .toString();
tickLabels[count] = i18n
  .t('heatingConstructionYear.formFields.constructionYear.options.today.label')
  .toString();

const config: StepDefinition = {
  component,
  hideSkipMessage: () => true,
  fields: [
    buildFormSlider('constructionYear', {
      component: {
        min,
        max,
        thumbLabel: 'always',
        tickLabels,
        trackColor: '#fabd02',
        trackFillColor: '#fabd02',
        showTicks: false,
      },
      groupId: FormFieldGroupId.OBJECT,
    }),
  ],
  id: StepId.HEATING_CONSTRUCTION_YEAR,
  categoryId: StepCategoryId.HEATING,
};

export default config;
