










































































import { defineComponent, ref, watch } from '@vue/composition-api';
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
} from '@fortawesome/pro-regular-svg-icons';
import SkipMessage from '@/components/SkipMessage.vue';
import { JUMP_TO_CONTACTFORM } from '~/consts/assistant-state-events';
import AssistantStep from '@/components/AssistantStep.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import AssistantStepsOverview from '@/components/AssistantStepsOverview.vue';
import { useBaseTransitions } from '@/lib/xstate/transitions';
import { getStepContext } from '@/lib/context';
import { stepComponentProps } from '@/lib/steps/helper';
import GcForm from '@/components/primitives/GcForm.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcButton from '@/components/primitives/GcButton.vue';
import FormField from '@/components/FormField.vue';
import { StepId } from '~/consts/assistant-steps';
import solarSteps from '~/steps/solar/steps';

export default defineComponent({
  components: {
    SkipMessage,
    GcForm,
    GcRow,
    GcCol,
    GcButton,
    FormField,
    AssistantStep,
    AssistantStepNavigation,
    AssistantStepsOverview,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const formData = getStepContext(props.assistantContext, props.step);
    const formValid = ref(false);
    const { onPrev } = useBaseTransitions(
      emit,
      props.assistantContext,
      props.step,
    );
    const solarStep = props.steps?.filter(
      (step) => step.id === StepId.SOLAR_POWER_USAGE,
    )[0];
    const currentStepIndex = 3;

    watch(
      () => [formData.value.personCount, formData.value.powerUsage],
      (value, oldValue) => {
        const personCount = value[0][0];
        const personCountOld = oldValue[0][0];

        if (personCount != personCountOld) {
          switch (personCount) {
            case '1':
              formData.value.powerUsage = 2400;
              break;
            case '2':
              formData.value.powerUsage = 3000;
              break;
            case '3':
              formData.value.powerUsage = 3600;
              break;
            case '4':
              formData.value.powerUsage = 4000;
              break;
            case '5+':
              formData.value.powerUsage = 5000;
              break;
          }
        }
      },
    );

    const onNext = () => {
      emit('transition', {
        event: 'JUMP_TO_' + StepId.SOLAR_OFFER.toUpperCase(),
      });
    };

    const jumpToContactForm = (): void => {
      emit('transition', {
        event: JUMP_TO_CONTACTFORM,
      });
    };

    return {
      formData,
      formValid,
      StepId,
      onPrev,
      onNext,
      jumpToContactForm,
      faCheck,
      faArrowLeft,
      faArrowRight,
      solarSteps,
      solarStep,
      context: props.assistantContext,
      currentStepIndex,
    };
  },
});
